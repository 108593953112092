import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";
import { handleClick } from "../function/handleClickl";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: kevin.rblas@gmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/kevinblas99",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/kevin-ramirez-70153422b/",
  },
  {
    icon: faStackOverflow,
    url: "https://stackoverflow.com/users/21130908/kevinblas99",
  },
];

const Header = () => {
  const [previousPos, setPreviousPos] = useState(0);
  //Use Reference Hook for the header to change its style property
  const headRef = useRef(document.getElementById('header-box'))

  //Use Effect hook that is attached to the scroll event
  //When the user scrolls down it will hide the header and when he/she scrolls down it will show the header.
  useEffect(()=>{

    const scrollEvent = ()=>{
      const currentPos = window.scrollY;
      if (previousPos < currentPos ){
        headRef.current.style.transform = `translateY(-200px)`;
      }else{
        headRef.current.style.transform = `translateY(0px)`;
      }
      setPreviousPos(currentPos);

    }

    window.addEventListener("scroll", scrollEvent);

    return (()=>{
      window.removeEventListener("scroll",scrollEvent);
    })

  },[previousPos]);


  return (
    <Box
    position="fixed"
    top={0}
    left={0}
    right={0}
    translateY={0}
    transitionProperty="transform"
    transitionDuration=".3s"
    transitionTimingFunction="ease-in-out"
    backgroundColor="#18181b"
    ref={headRef}
    style={{zIndex:"2"}}
    fontWeight = "bold"
    id="header-box"
    >
      <Box color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={15}
          py={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <nav>
            <HStack spacing={3}>
              {socials.map(({ icon, url }) => (
                <a
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={icon} key={url} size="lg"/>
                </a>
              ))}
         </HStack>
          </nav>
          <nav>
            <HStack spacing={3} fontSize={{base:"sm", md:"md"}} >
              <a href="#projects" onClick={handleClick("projects")}>Projects</a>
              <a href="#contactme" onClick={handleClick("contactme")}>Contact Me</a>
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
