import React, {useEffect, useRef} from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import FullScreenSection from "./FullScreenSection";
import useSubmit from "../hooks/useSubmit";
import {useAlertContext} from "../context/alertContext";
import emailjs from "@emailjs/browser";


const LandingSection = () => {
  const {response, submit} = useSubmit();
  const { onOpen } = useAlertContext();
  const formik = useFormik({
    initialValues: {
      firstName:"" ,
      email:"" ,
      type:"",
      comment:"",
    },
    onSubmit: (values) => {
      submit('http://localhost:3000/', values);
      emailjs.sendForm
      ('service_gzgsm5l',
      'template_uey1jfm', form.current,
       'ZbdS7SeEdurxquxae')
       .then(
        (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      });
    },

    validationSchema: Yup.object({
      firstName:Yup.string().required("Required"),
      email:Yup.string().email("Invalid email address").required("Required"),
      comment:Yup.string().min(20, "Must be at least 20 characters").required("Required"),
    }),
  });

    useEffect (() => {
      if (response) {
        onOpen(response.type , response.message);
        if (response.type === 'success')
        {formik.resetForm();
        }
      }
    }, [response, formik, onOpen]);

    const form = useRef();

  return (
    <FullScreenSection
      isDarkBackground
      backgroundColor="#660000"
      py={16}
    >
      <VStack maxWidth="1024px" p={0} alignItems="center">
        <Heading as="h1" id="contactme-section">
          Contact me
        </Heading>
        <Box p={6} backgroundColor="#660000" w={["xs","sm","md","lg","xl","2xl"]}>
          
        <form
        ref={form}
        onSubmit={formik.handleSubmit}

        >
            <VStack spacing={4}>
              <FormControl isInvalid={  formik.errors.firstName && formik.touched.firstName}>
                <FormLabel htmlFor="firstName">Name</FormLabel>
                <Input
                  id="firstName"
                  name="firstName"
                  {...formik.getFieldProps("firstName")}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="type">Type of enquiry</FormLabel>
                <Select id="type" name="type">
                  <option value="freelance">Freelance project proposal</option>
                  <option value="openSource">
                    Open source consultancy session
                  </option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <FormControl isInvalid={formik.errors.comment && formik.touched.comment}>
                <FormLabel htmlFor="comment">Your message</FormLabel>
                <Textarea
                  id="comment"
                  name="comment"
                  height={250}
                  {...formik.getFieldProps("comment")}
                />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button type="submit" colorScheme="linkedin" width="full">
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default LandingSection;
