import React from "react";
import { Box, Heading, VStack, Image, Text, Flex, Button, HStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import avatar from "../images/avatar.png"
import { BiCool } from "react-icons/bi";
import { handleClick } from "../function/handleClickl";

const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    backgroundColor="#191C27"
    color="white"
    pt="20"
    textAlign="center"
  >
    <Flex
      direction={{ base: "column", md: "row" }} // Stack columns on small screens, row on medium screens and larger
      justify="center"
      align="center"
      spacing={8}
      maxW="1200px" // Set a maximum width for the content
    >
      <Box boxSize={{ base: "200px", md: "400px" }}>
        <Image src={avatar} alt="Kevin Ramirez" borderRadius="full" />
      </Box>
      <VStack align="center" m="10" spacing={4} textAlign={{ base: "center", md: "center" }} boxSize="320px">
        <Heading as="h1" size="xl">
          Hey There!
        </Heading>
        <Text fontSize="lg" color="gray.500">
          My name is Kevin. I'm a Front End Developer that crafts efficient,
           intuitive web pages for users to have a great experience while
           navigating on the web.
        </Text>
        <HStack pt="10" spacing={8}>
        <Button 
          bgColor="ActiveCaption"
          onClick={handleClick("contactme")}
        ><BiCool/>Hire Me</Button>
        <Button
          bgColor="red.500"
          onClick={handleClick("contactme")}
        ><BiCool/>Hire Me</Button>
        </HStack>
      </VStack>
    </Flex>
  </FullScreenSection>
);

export default LandingSection;
