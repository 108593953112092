import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading, useBreakpointValue} from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "Bar Menu",
    description:
      "Styled using Tailwind CSS UI, and github for continuos integration. The content of the menu is populated by google sheets which facilitates the use of clients to update prices and items on their own.",
    getImageSrc: () => require("../images/opti-bar.png"),
    url: "https://www.halfmoonlounge.com/",
  },
  {
    title: "Dashboard",
    description:
      "Crafted with Next.js, Tailwind CSS, Vercel, PostgreSQL, and Git, offers seamless development with server-side rendering, responsive UI styling, easy hosting, efficient data management, and collaborative version control. ",
    getImageSrc: () => require("../images/dashboard.png"),
    url: "https://next-dash-dusky.vercel.app/",
  },
  {
    title: "Ecommerce Page",
    description:
      "Next.js for the front-end and to execute server actions dynamically, MongoDB and Prisma to register user logins and products as well as creating db modals, Tailwind to beautify . ",
    getImageSrc: () => require("../images/transpa1.png"),
    url: "https://next-ecom-phi-umber.vercel.app/",
  },
  {
    title: "Booking Table",
    description:
      "A booking page for a fictional resturant where customers can make reservations, and have an understanding of the restaurant's background.",
    getImageSrc: () => require("../images/opti-photo4.png"),
    url: "https://bookingtable.netlify.app/",
  },
];

const ProjectsSection = () => {
  const gridColumnValue = useBreakpointValue({base: "1fr", md: "repeat(2, minmax(0,1fr))"});
  return (
    <FullScreenSection
      backgroundColor="#EDEFEE"
      isDarkBackground
      p={3}
      spacing={5}
      py={20}
    >
      <Heading as="h1" textColor="#191C27" id="projects-section" textAlign="center" mb={5}>
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns={gridColumnValue}
        gridGap={5}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            url={project.url}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
