
import { Heading, Image, Text, VStack, Box, Link} from "@chakra-ui/react";
import React from "react";

const Card = ({ title, description, imageSrc, url}) => {
return (
  <Link href={url}>
  <Box background="white" maxW={["sm","md","lg"]} borderRadius="3xl" padding={3} boxShadow="dark-lg">
  <VStack spacing={1} align="-moz-initial">
  <Heading size="lg" color='black' p={2}><b>{title}</b></Heading>
  <Text color="blackAlpha.800" ><i>{description}</i></Text>
  <Image src={imageSrc} objectFit="fill" boxSize={["sm","md","lg"]} borderRadius="2xl" />
  <a href={url} className="clickclick" >
 {/* <Button alignContent="center" alignItems="center" color="#24A0ED" bgColor="blue.900" borderRadius="full"><b>See more</b></Button>
*/}
  </a>

</VStack>
</Box>
</Link>
);
};


export default Card;
